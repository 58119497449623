import { useTranslation } from "react-i18next"
import PageTitle from "../../components/PageTitle"
import WhiteSpace from "../../components/Whitespace"
import Comm from "../../Comm"
import { useEffect, useState } from "react"
import ChargeType from "../../types/ChargeType"
import ChargerType from "../../types/ChargerType"
import styled from "styled-components"
import ExportModal from "../../modules/ExportModal"
import Dropdown from "../../components/Dropdown"
import LoaderDots from "../../components/LoaderDots"
import Modal from "../../components/Modal"
import React from "react"
import Table from "../../modules/Table"
import { TableCellTypes } from "../../enums/TableCellTypes"
import ButtonWithIcon from "../../components/ButtonWithIcon"
import Icon from "../../Icon"

const ToolbarContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 26px;
  display: flex;
`

const RefreshTimerLabel = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 4px;
  text-align: center;
`

const StatCardTable = styled.table`
  width: 100%;
`

const StatCard = styled.div<{ $color: string }>`
  border-radius: 24px;
  padding: 24px;
  background-color: ${props => props.theme.colors[props.$color]};
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
`

const StatCardIconContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 24px;
  height: 24px;
  padding: 7px;
  border-radius: 100px;
  margin-top: 5px;
`

const StatCardTitle = styled.p<{ $white?: boolean }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
  margin-left: 18px;
`

const StatCardValue = styled.p<{ $white?: boolean }>`
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
  margin-left: 18px;
  margin-top: 6px;
`

const StatCardUnit = styled.span`
  font-weight: 500;
  font-size: 15px;
  padding-left: 6px;
`

const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
  color: ${props => props.theme.colors.gray_900};
  width: 20vw;
`

const ModalContainer = styled.div`
  padding: 24px;
  height: 85vh;
`

const ModalCloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`

const ChargeDetailContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`

const ChargeDetailLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: 4px;
  margin-left: 8px;
`

const ChargeDetailValue = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  right: 0px;
  margin: 0px;
  margin-top: 4px;
`

const ChargeDetailsPeriodContainer = styled.div`
  box-shadow: 0px 0px 10px 0px #0000001A;
  border-radius: 16px;
  padding: 20px;
  width: calc(20vw - 40px);
`

const ChargeDetailsPeriodTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const ChargeDetailsPeriodTimeLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const ChargeDetailsPeriodsContainer = styled.div`
  overflow-y: scroll;
  max-height: calc(85vh - 471px);
  padding: 10px;
  margin-left: -10px;
  margin-right: -10px;
`

export default function Charges({ charger }: { charger: ChargerType }) {
  const { t } = useTranslation()

  const [charges, setCharges] = useState<ChargeType[]>()
  const [lastRefresh, setLastRefresh] = useState<string>('')

  const [allChargedEnergy, setAllChargedEnergy] = useState<number>()
  const [allChargetime, setAllChargetime] = useState<number>()
  const [averageChargedEnergy, setAverageChargedEnergy] = useState<number>()
  const [allIdleTime, setAllIdleTime] = useState<number>()

  const [isShowingChargeDetails, setIsShowingChargeDetails] = useState<ChargeType | null>()

  async function fetchCharges() {
    const response = await Comm('/api-cdr/cdrs/last', {
      headers: { "Charger_ID": charger.chargerId }
    })
    const result = await response.json()
    result.reverse()
    setCharges(result)
    setLastRefresh(new Date().toString().split(' ')[4])


    // Calculate stats
    let chargedEnergy = 0
    let chargedTime = 0
    let avgChargedEnergy = 0
    let idleTime = 0
    for (const charge of result) {
      chargedEnergy += charge.chg_energy
      chargedTime += charge.chg_time
      avgChargedEnergy += charge.avg_power
      idleTime += charge.idle_time
    }
    avgChargedEnergy = avgChargedEnergy / result.length

    setAllChargedEnergy(chargedEnergy)
    setAllChargetime(chargedTime)
    setAverageChargedEnergy(avgChargedEnergy)
    setAllIdleTime(idleTime)
  }
  useEffect(() => {
    fetchCharges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleExportCharges(formats: string[]) {
    console.log(`TODO: Export charges in formats: ${formats}`)
  }

  function handelRefresh() {
    setCharges([])
    fetchCharges()
  }

  function secondsToHumanReadable(seconds: number) {
    if (seconds <= 3600) {
      return `${Math.floor(seconds / 60)}${t('charger.m')}`
    }

    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds - (hours * 3600)) / 60)
    if (hours == 0) {
      return `${hours}${t('charger.m')}`
    } else {
      return `
        ${hours}${t('charger.h')} 
        ${minutes}${t('charger.m')} 
      `
    }
  }

  function handleDownloadCharge(charge: ChargeType) {
    downloadObjectInBrowser(charge, `CDR_${charge.cdr_id}`)
  }

  function downloadObjectInBrowser(exportObj: unknown, exportName: string) {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj, null, 2))
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute("href", dataStr)
    downloadAnchorNode.setAttribute("download", exportName + ".json")
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }

  return (
    <>
      <WhiteSpace height={'12px'} />
      <PageTitle label={t('charger.charges')} />
      {isShowingChargeDetails && (
        <Modal align="right">
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingChargeDetails(null)} />
            </ModalCloseButtonContainer>
            <ModalTitle>{t('charger.chargeDetails')}</ModalTitle>
            <WhiteSpace height="34px" />
            {[
              { icon: "chargers.pluggedIn", label: t('charger.pluggedIn'), value: new Date(isShowingChargeDetails.s_start * 1000).toLocaleTimeString('hu') },
              { icon: "chargers.unplugged", label: t('charger.unplugged'), value: new Date(isShowingChargeDetails.s_end * 1000).toLocaleTimeString('hu') },
              { icon: "chargers.time", label: t('charger.chargingTime'), value: secondsToHumanReadable(isShowingChargeDetails.chg_time) },
              { icon: "chargers.sleep", label: t('charger.idleTime'), value: secondsToHumanReadable(isShowingChargeDetails.idle_time) },
              { icon: "chargers.energy", label: t('charger.energy'), value: `${isShowingChargeDetails.chg_energy} kWh` },
              { icon: "chargers.average", label: t('charger.averagePower'), value: `${isShowingChargeDetails.avg_power} kW` },
              { icon: "chargers.humidity", label: `${t('charger.humidity')} (max/min)`, value: `${isShowingChargeDetails.hum_max}% / ${isShowingChargeDetails.hum_min}%` },
              { icon: "chargers.thermometer", label: `PI (max/${t('charger.avg')})`, value: `${isShowingChargeDetails.temp_pi_max} °C / ${isShowingChargeDetails.temp_pi_avg} °C` },
              { icon: "chargers.thermometer", label: `POW (max/${t('charger.avg')})`, value: `${isShowingChargeDetails.temp_pow_max} °C / ${isShowingChargeDetails.temp_pow_avg} °C` },
              { icon: "chargers.thermometer", label: `UC (max/${t('charger.avg')})`, value: `${isShowingChargeDetails.temp_uc_max} °C / ${isShowingChargeDetails.temp_uc_avg} °C` }
            ].map(dataPoint => (
              <React.Fragment key={dataPoint.label}>
                <ChargeDetailContainer>
                  <Icon path={dataPoint.icon} width="24px" height="24px" />
                  <ChargeDetailLabel>{dataPoint.label}</ChargeDetailLabel>
                  <ChargeDetailValue>{dataPoint.value}</ChargeDetailValue>
                </ChargeDetailContainer>
                <WhiteSpace height="16px" />
              </React.Fragment>
            ))}
            <WhiteSpace height="16px" />
            <ChargeDetailsPeriodsContainer>
              {isShowingChargeDetails.periods.map(period => (
                <React.Fragment key={`period${period.index}`}>
                  <ChargeDetailsPeriodContainer>
                    <ChargeDetailsPeriodTitle>{`${t('charger.period')} ${period.index + 1}`}</ChargeDetailsPeriodTitle>
                    <ChargeDetailsPeriodTimeLabel>{`${new Date(period.p_start * 1000).toISOString().split('T')[1].split('.')[0]} - ${new Date(period.p_end * 1000).toISOString().split('T')[1].split('.')[0]}`}</ChargeDetailsPeriodTimeLabel>
                    <WhiteSpace height="16px" />
                    <ChargeDetailContainer>
                      <Icon path="chargers.energy" width="24px" height="24px" />
                      <ChargeDetailLabel>{t('charger.energy')}</ChargeDetailLabel>
                      <ChargeDetailValue>{`${period.p_energy} kWh`}</ChargeDetailValue>
                    </ChargeDetailContainer>
                  </ChargeDetailsPeriodContainer>
                  <WhiteSpace height="16px" />
                </React.Fragment>
              ))}
            </ChargeDetailsPeriodsContainer>
          </ModalContainer>
        </Modal>
      )}
      <StatCardTable>
        <tbody>
          <tr>
            <td style={{ width: '20%' }}>
              <StatCard $color="dark">
                <StatCardIconContainer>
                  <Icon path="chargers.power" width="100%" height="100%" />
                </StatCardIconContainer>
                <div>
                  <StatCardTitle $white>{t('charger.allCharges')}</StatCardTitle>
                  <StatCardValue $white>{charges ? charges.length : <LoaderDots />}<StatCardUnit>{t('charger.pcs')}</StatCardUnit></StatCardValue>
                </div>
              </StatCard>
            </td>
            <td style={{ width: '20%' }}>
              <StatCard $color="warning">
                <StatCardIconContainer>
                  <Icon path="chargers.energy" width="100%" height="100%" color="warning" />
                </StatCardIconContainer>
                <div>
                  <StatCardTitle>{t('charger.allChargedEnergy')}</StatCardTitle>
                  <StatCardValue>{allChargedEnergy ? allChargedEnergy.toFixed(2) : <LoaderDots />}<StatCardUnit>kWh</StatCardUnit></StatCardValue>
                </div>
              </StatCard>
            </td>
            <td style={{ width: '20%' }}>
              <StatCard $color="primary">
                <StatCardIconContainer>
                  <Icon path="chargers.time" width="100%" height="100%" color="primary" />
                </StatCardIconContainer>
                <div>
                  <StatCardTitle>{t('charger.allChargetime')}</StatCardTitle>
                  <StatCardValue>{allChargetime ? secondsToHumanReadable(allChargetime) : <LoaderDots />}</StatCardValue>
                </div>
              </StatCard>
            </td>
            <td style={{ width: '20%' }}>
              <StatCard $color="dark">
                <StatCardIconContainer>
                  <Icon path="chargers.average" width="100%" height="100%" />
                </StatCardIconContainer>
                <div>
                  <StatCardTitle $white>{t('charger.averageChargedEnergy')}</StatCardTitle>
                  <StatCardValue $white>{averageChargedEnergy ? averageChargedEnergy.toFixed(2) : <LoaderDots />}<StatCardUnit>kWh</StatCardUnit></StatCardValue>
                </div>
              </StatCard>
            </td>
            <td style={{ width: '20%' }}>
              <StatCard $color="gray_300">
                <StatCardIconContainer>
                  <Icon path="chargers.sleep" width="100%" height="100%" color="gray_600" />
                </StatCardIconContainer>
                <div>
                  <StatCardTitle>{t('charger.allIdleTime')}</StatCardTitle>
                  <StatCardValue>{allIdleTime ? secondsToHumanReadable(allIdleTime) : <LoaderDots />}</StatCardValue>
                </div>
              </StatCard>
            </td>
          </tr>
        </tbody>
      </StatCardTable>
      <ToolbarContainer>
        <div>
          <ButtonWithIcon icon="general.refresh" label={t('charger.refresh')} onClick={handelRefresh} backgroundColor="success" iconColor="white" />
          <RefreshTimerLabel>{lastRefresh}</RefreshTimerLabel>
        </div>
        <WhiteSpace width='8px' />
        <div>
          <ExportModal onExport={handleExportCharges} title={t('charger.exportCharges')} />
        </div>
      </ToolbarContainer>
      {charges && charges.length > 0 && (
        <Table id="Charges" height="calc(90vh - 245px)" isSearchEnabled={false} isPaginationEnabled={true} columns={[
          { width: '30px' },
          { label: t('charger.chargeIdChargingMode'), width: '14%' },
          { label: t('charger.chargingStart'), width: '14%' },
          { label: t('charger.chargingEnd'), width: '14%' },
          { label: t('charger.chargedEnergy'), width: '14%' },
          { label: t('charger.chargingTime'), width: '14%' },
          { label: t('charger.averagePower'), width: '14%' },
          { label: t('charger.numberOfChargingPeriods') },
          { width: '20px' },
        ]} data={charges.map(charge => [
          {
            onClick: () => setIsShowingChargeDetails(charge),
            value: {
              type: TableCellTypes.Image,
              src: (() => {
                switch (charge.periods && charge.periods[0] && charge.periods[0].p_stop_ev ? charge.periods[0].p_stmode : false) {
                  case 2: return "chargers.scheduledCharging"
                  case 3: return "chargers.kWhBasedCharging"
                  default: return "chargers.normalCharging"
                }
              })()
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.cdr_id,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: (() => {
                switch (charge.periods && charge.periods[0] && charge.periods[0].p_stop_ev ? charge.periods[0].p_stmode : false) {
                  case 2: return t('charger.scheduledCharging')
                  case 3: return t('charger.kWhBasedCharging')
                  default: return t('charger.normalCharging')
                }
              })(),
              value2: charge.cdr_id.toString()
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.s_start,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: new Date(charge.s_start * 1000).toLocaleDateString('hu'),
              value2: new Date(charge.s_start * 1000).toLocaleTimeString('hu')
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.s_end,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: new Date(charge.s_end * 1000).toLocaleDateString('hu'),
              value2: new Date(charge.s_end * 1000).toLocaleTimeString('hu')
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.chg_energy,
            value: {
              type: TableCellTypes.LabelWithIcon,
              value: `${+charge.chg_energy.toFixed(2)} kWh`,
              src: "chargers.energy",
              iconColor: "warning"
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.chg_time,
            value: {
              type: TableCellTypes.LabelWithIcon,
              value: secondsToHumanReadable(charge.chg_time),
              src: "chargers.time",
              iconColor: "primary"
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.avg_power,
            value: {
              type: TableCellTypes.LabelWithIcon,
              value: `${+charge.avg_power.toFixed(2)} kW`,
              src: "chargers.average"
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.periods ? charge.periods.length : 0,
            value: {
              type: TableCellTypes.Label,
              value: charge.periods ? charge.periods.length.toString() : '0'
            }
          }, {
            custom: <Dropdown handleChoose={(value) => value == t('charger.download') ? handleDownloadCharge(charge) : setIsShowingChargeDetails(charge)} title={{ icon: "general.more", label: '' }} options={[{ icon: "general.details", label: t('charger.details') }, { icon: "general.download", label: t('charger.download') }]} showOptionContainerOnLeft />
          }
        ])} />
      )}
    </>
  )
}