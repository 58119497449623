import { useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import styled, { ThemeProvider } from "styled-components"
import Menu from "./modules/Menu"
import GlobalStyle from "./GlobalStyle"
import { lightTheme, darkTheme } from "./GlobalTheme"
import Dropdown from "./components/Dropdown"
import { useTranslation } from "react-i18next"
import fbConf from "./FirebaseConf"
import { getAuth } from "firebase/auth"
import Button from "./components/Button"
import Icon from "./Icon"

const OutletContainer = styled.div<{ $isMenuOpen: boolean }>`
  position: absolute;
  right: 20px;
  bottom: 0px;
  height: 90%;
  width: ${props => props.$isMenuOpen ? '83%' : 'calc(100% - 20px - 2% - 30px - (16px * 2))'};
  transition: width .2s ease-in-out;
`

const LogoContainer = styled.div<{ $isMenuOpen: boolean }>`
  width: ${props => props.$isMenuOpen ? '10vw' : '2vw'};
  margin-left: ${props => props.$isMenuOpen ? '2.5vw' : '1.75vw'};;
  margin-top: 3.5vh;
`

const DividerLine = styled.div<{ $isMenuOpen: boolean }>`
  width: ${props => props.$isMenuOpen ? '12vw' : 'calc(2vw + 30px + (16px * 2))'};
  height: 1px;
  margin-left: ${props => props.$isMenuOpen ? '1.6vw' : '0px'};
  margin-top: 1.6vh;
  margin-bottom: 2vh;
  background-image: linear-gradient(to right, transparent, #E0E1E2, transparent);
  transition: width .2s ease-in-out, margin-left .2s ease-in-out;
`

const MenuTriggerButtonContainer = styled.div<{ $isMenuOpen: boolean }>`
  position: absolute;
  top: 5.25vh;
  margin-left: ${props => props.$isMenuOpen ? '13vw' : '4.25vw'};
  width: 16px;
  height: 16px;
  cursor: pointer;
`

const TopRightMenuContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 35px;
  display: flex;
`

const SignoutButton = styled.p`
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 700;
  color: ${props => props.theme.colors.gray_900};
`

const BottomSectionContainerBig = styled.div`
  position: absolute;
  bottom: 0px;
`

const FeedbackBackground = styled.div`
  position: relative;
  width: 11.5vw;
  height: 210px;
  margin-left: 2vw;
  background: linear-gradient(270deg, #64FFEB 0%, #2A6FF4 100%);
  border-radius: 16px;
  overflow: hidden;
`

const FeedBackContainerBlob = styled.div`
  position: absolute;
  width: 10vw;
  height: 10vw;
  left: 2vw;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, #FF29C3 100%);
  border-radius: 100vw;
  filter: blur(30px);
`

const FeedbackIconContainer = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${props => props.theme.colors.white};
  padding: 6px;
  border-radius: 12px;
  margin-left: 16px;
  margin-top: 16px;
  position: relative;
`

const FeedbackTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.white};
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 16px;
  position: relative;
`

const FeedbackLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.white};
  margin-top: 8px;
  margin-bottom: 26px;
  margin-left: 16px;
  position: relative;
`

const TimelockContainer = styled.div<{ $isMenuOpen: boolean }>`
  display: flex;
  margin-left: ${props => props.$isMenuOpen ? 'calc(2vw + 47px)' : '16px'};
  margin-top: 16px;
`

const TimelockLabel = styled.p<{ $isMenuOpen: boolean }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_600};
  margin: 0px;
  margin-left: 8px;
  width: ${props => props.$isMenuOpen ? 'auto' : '1px'};
`

const LastLoginLabel = styled.p<{ $isMenuOpen: boolean }>`
  width: ${props => props.$isMenuOpen ? '11.5vw' : '65px'};
  margin-left: ${props => props.$isMenuOpen ? '2vw' : '20px'};
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 8px;
`

const VersionLabel = styled.p<{ $isMenuOpen: boolean }>`
  width: ${props => props.$isMenuOpen ? '11.5vw' : '65px'};
  margin-left: ${props => props.$isMenuOpen ? '2vw' : '20px'};
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 16px;
`

function App() {
  const [VERSION] = useState<string>('v0.1.23')

  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const location = useLocation()

  const [isAdmin, setIsAdmin] = useState<boolean>()

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(localStorage.getItem('preferMenuClosed') === 'false' || !localStorage.getItem('preferMenuClosed'))
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('dashboard')
  const [theme, setTheme] = useState<string>(localStorage.getItem('preferedTheme') == 'system' ? (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light') : localStorage.getItem('preferedTheme') as string)
  const [timelockSeconds, setTimelockSeconds] = useState<number>(60 * 60)


  // Decide if user is Admin
  useEffect(() => {
    async function decideIfUserIsAdmin() {
      const idTokenResult = await getAuth(fbConf).currentUser?.getIdTokenResult()
      if (idTokenResult?.claims.admin) {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
      console.log(`Admin: ${isAdmin}`)
    }
    getAuth(fbConf).onAuthStateChanged(() => {
      decideIfUserIsAdmin()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Redirect non-Admin user to dashboard if on a non-approved page
  /*useEffect(() => {
    if (isAdmin === false) {
      const nonAdminApprovedPaths = ['', 'chargers']
      if (!nonAdminApprovedPaths.includes(location.pathname.split('/')[1])) {
        navigate('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])*/


  // Redirect to login page if not logged in
  useEffect(() => {
    getAuth(fbConf).onAuthStateChanged(user => {
      if (!user) {
        navigate('/login')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleSwitchMenuState() {
    setIsMenuOpen(!isMenuOpen)
    localStorage.setItem('preferMenuClosed', isMenuOpen.toString())
  }

  // Set language to preferred
  useEffect(() => {
    if (localStorage.getItem('preferedLanguage') == 'hu') {
      i18n.changeLanguage('hu')
    } else {
      i18n.changeLanguage('en')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleChooseDropdownOption(label: string) {
    switch (label) {
      case 'Magyar':
        i18n.changeLanguage('hu')
        localStorage.setItem('preferedLanguage', 'hu')
        break
      case 'English':
        i18n.changeLanguage('en')
        localStorage.setItem('preferedLanguage', 'en')
        break
      case t('login.light'):
        setTheme('light')
        localStorage.setItem('preferedTheme', 'light')
        break
      case t('login.dark'):
        setTheme('dark')
        localStorage.setItem('preferedTheme', 'dark')
        break
      case t('login.system'):
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          setTheme('dark')
        } else {
          setTheme('light')
        }
        localStorage.setItem('preferedTheme', 'system')
        break
    }
  }

  async function handleSignOut() {
    await getAuth(fbConf).signOut()
    localStorage.removeItem('token')
  }

  useEffect(() => {
    const timelockInterval = setTimeout(() => {
      if (timelockSeconds <= 0) {
        handleSignOut()
      } else {
        setTimelockSeconds(timelockSeconds - 1)
      }
    }, 1000)

    return () => clearTimeout(timelockInterval)
  }, [timelockSeconds])

  useEffect(() => {
    setTimelockSeconds(60 * 60)
  }, [location.pathname])

  // Detech light/dark theme change
  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      if (localStorage.getItem('preferedTheme') == 'system') {
        event.matches ? setTheme('dark') : setTheme('light')
      }
    })
  }, [])

  return (
    <>
      <ThemeProvider theme={theme == 'dark' ? darkTheme : lightTheme}>
        <GlobalStyle />
        <TopRightMenuContainer>
          <Dropdown handleChoose={handleChooseDropdownOption} title={{ icon: localStorage.getItem('preferedTheme') == 'system' ? "app.systemTheme" : theme == 'dark' ? "app.darkmode" : "app.lightmode", label: '', color: "gray_600" }} options={[{ icon: "app.lightmode", label: t('login.light'), color: "gray_600" }, { icon: "app.darkmode", label: t('login.dark'), color: "gray_600" }, { icon: "app.systemTheme", label: t('login.system'), color: "gray_600" }]} />
          <Dropdown handleChoose={handleChooseDropdownOption} title={{ icon: i18n.language == 'en' ? "app.flagUk" : "app.flagHun", label: '' }} options={[{ icon: "app.flagUk", label: 'English' }, { icon: "app.flagHun", label: 'Magyar' }]} />
          <SignoutButton onClick={handleSignOut}>{t('app.signout')}</SignoutButton>
        </TopRightMenuContainer>
        <LogoContainer $isMenuOpen={isMenuOpen}>
          <Icon path={isMenuOpen ? "general.logoDashboardHorizontal" : "general.charger"} width="100%" height="100%" color="none" />
        </LogoContainer>
        <DividerLine $isMenuOpen={isMenuOpen} />
        <Menu isMenuOpen={isMenuOpen} selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem} />
        <BottomSectionContainerBig>
          {isMenuOpen ? (
            <FeedbackBackground>
              <FeedBackContainerBlob />
              <FeedbackIconContainer>
                <Icon path="app.mail" width="100%" height="100%" />
              </FeedbackIconContainer>
              <FeedbackTitle>{t('app.missingSomething')}</FeedbackTitle>
              <FeedbackLabel>{t('app.helpUsImporveInTheRightDirection')}</FeedbackLabel>
              <div style={{ position: 'relative', marginLeft: '16px' }}>
                <Button label={t('app.sendFeedback')} width='calc(11.5vw - 32px)' onclick={() => window.open(`mailto:help@voltie.eu?subject=Voltie Dashboard visszajelzés`)} bgcolor="white" labelcolor="black"></Button>
              </div>
            </FeedbackBackground>
          ) : (
            <div style={{ marginLeft: '16px', cursor: 'pointer' }} onClick={() => window.open(`mailto:help@voltie.eu?subject=Voltie Dashboard visszajelzés`)}>
              <FeedbackIconContainer>
                <Icon path="app.mail" width="100%" height="100%" />
              </FeedbackIconContainer>
            </div>
          )}
          <TimelockContainer $isMenuOpen={isMenuOpen}>
            <Icon path="app.key" width="16px" height="16px" color="gray_600" />
            <TimelockLabel $isMenuOpen={isMenuOpen}>{`${isMenuOpen ? `${t('app.timelock')}:` : ''} ${Math.floor(timelockSeconds / 60)}:${String(timelockSeconds % 60).padStart(2, '0')}`}</TimelockLabel>
          </TimelockContainer>
          <LastLoginLabel $isMenuOpen={isMenuOpen}>{`${t('app.lastLogin')}: ${localStorage.getItem('lastLogin') || '-'}`}</LastLoginLabel>
          <VersionLabel $isMenuOpen={isMenuOpen}>{VERSION}</VersionLabel>
        </BottomSectionContainerBig>
        <OutletContainer $isMenuOpen={isMenuOpen}>
          <Outlet context={[theme]} />
        </OutletContainer>
        <MenuTriggerButtonContainer $isMenuOpen={isMenuOpen} onClick={handleSwitchMenuState}>
          <Icon path={isMenuOpen ? "general.backArrow" : "general.forwardArrow"} width="100%" height="100%" color="primary" />
        </MenuTriggerButtonContainer>
      </ThemeProvider>
    </>
  )
}

export default App
