import { useTranslation } from "react-i18next"
import PageTitle from "../components/PageTitle"
import styled from "styled-components"
import { useEffect, useRef, useState } from "react"
import { child, get, getDatabase, ref, set, update } from "firebase/database"
import fbConf from "../FirebaseConf"
import WhiteSpace from "../components/Whitespace"
import { useOutletContext } from "react-router-dom"
import Dropdown from "../components/Dropdown"
import Modal from "../components/Modal"
import Input from "../components/Input"
import Select from "../components/Select"
import InputWithIcon from "../components/InputWithIcon"
import LoaderDots from "../components/LoaderDots"
import Button from "../components/Button"
import ListItemWithDeleteButton from "../components/ListItemWithDeleteButton"
import React from "react"
import ListItem from "../components/ListItem"
import Comm from "../Comm"
import ButtonWithIcon from "../components/ButtonWithIcon"
import Notification from "../components/Notification"
import dateToTimeAgoString from "../utils/dateToTimeAgoString"
import Navigator from "../components/Navigator"
import Icon from "../Icon"

interface ChargerType {
  chargerId: string,
  fwVersion: string,
  osVersion: string,
  swVersion: string,
  fwVersionNumber: string,
  osVersionNumber: string,
  swVersionNumber: string,
  userEmail: string,
  online: boolean,
  lastPresence: string,
  userId: string,
  localName: string,
  comments: { author: string, comment: string }[]
}

interface ChargerGroupType {
  chargers: {
    [key: string]: ChargerType
  },
  groupFwVersion: string,
  groupOsVersion: string,
  groupSwVersion: string,
  lastEdit?: string
}

interface ChargerGroupsType {
  [key: string]: ChargerGroupType
}

const GroupsContainer = styled.div`
  width: 100%;
  height: calc(90vh - 113px);
  overflow-y: scroll;
`

const GroupContainer = styled.div`
  width: calc(100% - 30px - 16px - 39px);
  margin-left: 25px;
  margin-bottom: 8px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 24px;
  padding-top: 10px;
  padding-left: 39px;
  position: relative;
`

const GroupNameLabel = styled.p`
  font-weight: 700;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 30px;
  margin-top: 10px;
  width: 10vw;
`

const GroupInfoContainer = styled.td<{ $width?: string }>`
  display: flex;
  width: ${props => props.$width ? props.$width : 'auto'};
`

const GroupInfoLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin-top: 16px;
`

const GroupInfoValue = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin-top: 16px;
`

const DeleteButtonContainer = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 28px;
  right: 100px;
  cursor: pointer;

  &:active {
    transform: scale(.9);
  }
`

const EditButtonContainer = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 28px;
  right: 70px;
  cursor: pointer;

  &:active {
    transform: scale(.9);
  }
`

const OpenButtonContainer = styled.div<{ $isOpen: boolean }>`
  width: 16px;
  height: 16px;
  position: absolute;
  padding: 7px;
  background-color: #EFF2F5;
  border-radius: 6px;
  top: 22px;
  right: 24px;
  transform: rotate(${props => props.$isOpen ? '0deg' : '180deg'});
  cursor: pointer;

  &:active {
    transform: rotate(${props => props.$isOpen ? '0deg' : '180deg'}) scale(.9);
  }
`

const GroupDividerLine = styled.div`
  width: 98%;
`

const GroupChargersTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const GroupChargersTableLabel = styled.p`
  font-weight: 600;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_500};
`

const ChargerIdLabel = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin-top: 14px;
`

const ChargerEmailLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin-top: 16px;
`

const ChargerLocalNameLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin-top: 16px;
`

const ChargerSoftwareVerisonLabel = styled.p<{ $isMismatch?: boolean }>`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.$isMismatch ? props.theme.colors.danger : props.theme.colors.gray_500};
  top: 4px;
  position: absolute;
`

const ChargerTableRow = styled.tr`
  border-bottom: 1px dashed ${props => props.theme.colors.gray_300};
  position: relative;
`

const ChargerInfoRow = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.gray_300};
`

const ChargerStatus = styled.p<{ $status: string }>` // online / offline / disabled / charging
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.colors.white};
  border-radius: 40px;
  width: 70px;
  line-height: 22px;
  text-align: center;
  background-color: ${props => {
    switch (props.$status) {
      case 'online':
        return props.theme.colors.success
      case 'offline':
        return props.theme.colors.gray_400
      case 'disabled':
        return props.theme.colors.gray_900
      case 'charging':
        return props.theme.colors.primary
    }
  }};
`

const ModalCloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`

const ModalContainer = styled.div`
  padding: 24px;
  height: 85vh;
  overflow-y: scroll;
`

const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
  color: ${props => props.theme.colors.gray_900};
`

const ModalCategoryTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  margin: 0px;
  color: ${props => props.theme.colors.gray_900};
`

const ModalCategoryDivider = styled.div`
  width: calc(18vw + 24px);
  height: 4px;
  border-radius: 4px;
  background-image: linear-gradient(to right, #2A6FF4, #E2594B);
`

const ModalChargerContainer = styled.div`
  width: calc(100% - 12px);
  background-color: ${props => props.theme.colors.white};
  padding: 6px;
  border-radius: 12px;
  display: flex;
  margin-top: 16px;
  cursor: pointer;
`

const ModalChargerLabel = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin-top: 10px;
  margin-left: 12px;
`

const ModalChargerValue = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin-top: 18px;
  margin-left: 12px;
`

const ModalSaveButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 10px);
  background-color: ${props => props.theme.colors.background};
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`

const ModalSaveButton = styled.p`
  font-weight: 600;
  font-size: 15px;
  color: ${props => props.theme.colors.white};
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 16px;
  width: 20%;
  text-align: center;
  border-radius: 12px;
  float: right;
  background-color: ${props => props.theme.colors.dark};
  cursor: pointer;
`

const ModalCancelButton = styled.p`
  font-weight: 600;
  font-size: 15px;
  color: ${props => props.theme.colors.gray_500};
  padding-top: 8px;
  padding-bottom: 8px;
  width: 20%;
  text-align: center;
  float: right;
  cursor: pointer;
`

const ToolbarButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 26px;
  display: flex;
`

const SmallModalContainer = styled.div`
  padding: 24px;
`

const SmallModalLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
`

const SmallModalButtonContainer = styled.div`
  width: 15vw;
  display: flex;
`

const BigModalContainer = styled.div`
  padding-left: 88px;
  padding-right: 88px;
  padding-top: 65px;
  padding-bottom: 65px;
`

const UploadFilesTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  color: ${props => props.theme.colors.gray_900};
  width: 22vw;
  text-align: center;
  margin: 0px;
`

const UploadFilesSubTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const FileDropContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.primaryLight};
  border: 1px dashed ${props => props.theme.colors.primary};
  cursor: pointer;
`

const FileDropLabel = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  width: 100%;
  text-align: center;
  margin: 0px;
`

const DragDropFilesErrorLabel = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.colors.danger};
  width: 100%;
  text-align: center;
`

const ChargerSoftwareIconContainer = styled.div`
  width: 12px;
  height: 12px;
  margin-top: -4px;
  cursor: pointer;
`

const TableTimeAgoLabel = styled.p`
  font-weight: 400;
  font-size: 11px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin-top: -4px;
  width: 100px;
  margin-left: -15px;
  text-align: center;
`

const FilterDropdownContainer = styled.div<{ $isActive: boolean }>`
  background-color: ${props => props.$isActive ? '#673BE5' : props.theme.colors.dark};
  height: 40px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 40px;
  display: flex;
  cursor: pointer;
`

export default function ChargerGroups() {
  const { t } = useTranslation()
  const [theme] = useOutletContext<string>()

  const [chargerGroups, setChargerGroups] = useState<ChargerGroupsType>()
  const [openGroupName, setOpenGroupName] = useState<string | null>()
  const [bucketFiles, setBucketFiles] = useState<{ swVersion: string[], fwVersion: string[], osVersion: string[] }>({ swVersion: [], fwVersion: [], osVersion: [] })
  const scrollToGroupRef = useRef<(HTMLElement)[]>([])

  const [editingGroup, setEditingGroup] = useState<string | null>() // Holds the charger group name when modal open
  const [isAddingGroup, setIsAddingGroup] = useState<boolean>(false)
  const [isDeletingGroup, setIsDeletingGroup] = useState<string | null>() // Holds the charger group name when modal open
  const [isMovingCharger, setIsMovingCharger] = useState<{ charger: string, chargerGroup: string } | null>()
  const [movingChargerSelectorValue, setMovingChargerSelectorValue] = useState<string | null>()

  const [OSVersionSelectorValue, setOSVersionSelectorValue] = useState<string>()
  const [SWVersionSelectorValue, setSWVersionSelectorValue] = useState<string>()
  const [FWVersionSelectorValue, setFWVersionSelectorValue] = useState<string>()

  const [editChargerGroup_SearchResults, setEditChargerGroup_SearchResults] = useState<string[]>([])
  const [editChargerGroup_AddedChargers, setEditChargerGroup_AddedChargers] = useState<string[]>([])

  const [editChargerGroup_NameInput, setEditChargerGroup_NameInput] = useState<string>()
  const [editChargerGroup_isLoading, setEditChargerGroup_isLoading] = useState<boolean>(false)

  const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false)
  const [addedFilesList, setAddedFilesList] = useState<File[]>([])
  const [uploadedFilesList, setUploadedFilesList] = useState<File[]>([])
  const [dragDropFilesError, setDragDropFilesError] = useState<string | null>()

  const [globalSearchResults, setGlobalSearchResults] = useState<{ [key: string]: string[] } | null>({})

  const [editingChargerSoftwareVerion, setEditingChargerSoftwareVerion] = useState<{ chargerId: string, software: string /* OS / SW / FW */ } | null>()
  const [notification, setNotification] = useState<string>('')
  const [filterDropdownValue, setFilterDropdownValue] = useState<string>('All')

  // Check for duplicate chargers, slows a little bit, should remove if well tested
  useEffect(() => {
    if (chargerGroups) {
      const chargerList: string[] = []
      for (const group in chargerGroups) {
        for (const charger in chargerGroups[group].chargers) {
          if (chargerList.includes(charger)) {
            console.log('Duplicate:', charger, group)
          } else {
            chargerList.push(charger)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargerGroups])

  async function fetchChargerGroups() {
    const dbRef = ref(getDatabase(fbConf))
    const response = await get(child(dbRef, 'voltie/updategroups'))
    const result = response.val()

    const detailsResponse = await Comm('/api-charger/chargers', {})
    const details: ChargerType[] = await detailsResponse.json()

    const detailsObject: { [key: string]: { userEmail: string, online: boolean, osVersionNumber: string, swVersionNumber: string, fwVersionNumber: string, lastPresence: string, userId: string, localName: string } } = {}
    for (const charger of details) {
      detailsObject[charger.chargerId] = { userEmail: charger.userEmail, online: charger.online, osVersionNumber: charger.osVersion, swVersionNumber: charger.swVersion, fwVersionNumber: charger.fwVersion, lastPresence: charger.lastPresence, userId: charger.userId, localName: charger.localName }
    }

    for (const group in result) {
      for (const charger in result[group].chargers) {
        result[group].chargers[charger].userEmail = detailsObject[charger].userEmail
        result[group].chargers[charger].online = detailsObject[charger].online
        result[group].chargers[charger].osVersionNumber = detailsObject[charger].osVersionNumber
        result[group].chargers[charger].swVersionNumber = detailsObject[charger].swVersionNumber
        result[group].chargers[charger].fwVersionNumber = detailsObject[charger].fwVersionNumber
        result[group].chargers[charger].lastPresence = detailsObject[charger].lastPresence
        result[group].chargers[charger].userId = detailsObject[charger].userId
        result[group].chargers[charger].localName = detailsObject[charger].localName
      }
    }

    setChargerGroups(result)
    console.log(result)
  }

  async function fetchBucketFiles() {
    const response = await Comm('/api-firmware/bucketfiles', {})
    const result = await response.json()
    setBucketFiles(result)
  }

  useEffect(() => {
    fetchChargerGroups()
    fetchBucketFiles()
  }, [])

  function handleOpenGroup(groupName: string, groupIndex: number) {
    if (openGroupName == groupName) {
      setOpenGroupName(null)
    } else {
      setOpenGroupName(groupName)

      // Scroll to group
      setTimeout(() => {
        scrollToGroupRef.current[groupIndex].scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
  }

  function handleEditChargerGroup_Search(input: string) {
    if (chargerGroups) {
      if (input != '') {
        const result = []
        for (const charger in { ...chargerGroups['Default'].chargers, ...(editingGroup ? chargerGroups[editingGroup].chargers : []) }) {
          if (charger.toLocaleLowerCase().includes(input.toLocaleLowerCase())) {
            result.push(charger)
          }
        }
        setEditChargerGroup_SearchResults(result)
      } else {
        setEditChargerGroup_SearchResults(Object.keys({ ...chargerGroups['Default'].chargers, ...(editingGroup ? chargerGroups[editingGroup].chargers : []) }))
      }
    }
  }

  function handleEditChargerGroup_AddCharger(charger: string) {
    setEditChargerGroup_AddedChargers([...editChargerGroup_AddedChargers, charger])
  }

  function handleEditChargerGroup_RemoveCharger(charger: string) {
    const addedChargersWithoutCharger = [...editChargerGroup_AddedChargers]
    addedChargersWithoutCharger.splice(addedChargersWithoutCharger.indexOf(charger), 1)
    setEditChargerGroup_AddedChargers(addedChargersWithoutCharger)
  }

  async function handleAddNewGroupModalOpen() {
    if (chargerGroups) {
      setEditChargerGroup_SearchResults(Object.keys(chargerGroups['Default'].chargers))
      setEditChargerGroup_AddedChargers([])
      setIsAddingGroup(true)
    }
  }

  function handleEditGroupModalOpen(group: string) {
    if (chargerGroups) {
      setEditChargerGroup_SearchResults(Object.keys({ ...chargerGroups['Default'].chargers, ...chargerGroups[group].chargers }))
      setEditChargerGroup_AddedChargers(Object.keys(chargerGroups[group].chargers))
      setEditingGroup(group)
    }
  }

  async function handleAddNewChargerGroup() {
    setEditChargerGroup_isLoading(true)
    if (chargerGroups) {
      if (editChargerGroup_NameInput) {

        const dbRef = ref(getDatabase(fbConf))
        const response = await get(child(dbRef, `voltie/updategroups/${editChargerGroup_NameInput}`))
        if (!response.val()) {

          const chargersToAdd: { [key: string]: { fwVersion: string, swVersion: string, osVersion: string, comments: { author: string, comment: string }[] } } = {}
          for (const charger of editChargerGroup_AddedChargers) {
            chargersToAdd[charger] = {
              fwVersion: chargerGroups['Default'].chargers[charger].fwVersion,
              swVersion: chargerGroups['Default'].chargers[charger].swVersion,
              osVersion: chargerGroups['Default'].chargers[charger].osVersion,
              comments: chargerGroups['Default'].chargers[charger].comments || null
            }
          }
          await set(ref(getDatabase(fbConf), `voltie/updategroups/${editChargerGroup_NameInput}`), {
            chargers: chargersToAdd,
            groupOsVersion: OSVersionSelectorValue != '-' ? OSVersionSelectorValue : '',
            groupSwVersion: SWVersionSelectorValue != '-' ? SWVersionSelectorValue : '',
            groupFwVersion: FWVersionSelectorValue != '-' ? FWVersionSelectorValue : '',
            lastEdit: Date.now()
          })

          const toDeleteFromDefault: { [key: string]: null } = {}
          for (const charger of editChargerGroup_AddedChargers) {
            toDeleteFromDefault[`voltie/updategroups/Default/chargers/${charger}`] = null
          }
          await update(dbRef, toDeleteFromDefault)


          await fetchChargerGroups()
          setEditingGroup(null)
          setIsAddingGroup(false)

        } else {
          console.log('TODO: show error: charger group already exists')
        }
      } else {
        console.log('TODO: show error: name cant be empty')
      }
    }
    setEditChargerGroup_isLoading(false)
  }

  async function handleSaveEditedChargerGroup() {
    setEditChargerGroup_isLoading(true)
    if (chargerGroups && editingGroup) {

      // Add all now selected chargers to group
      const chargersToAdd: { [key: string]: { fwVersion: string, swVersion: string, osVersion: string, comments: { author: string, comment: string }[] } } = {}
      for (const charger of editChargerGroup_AddedChargers) {
        if (chargerGroups['Default'].chargers[charger]) {
          chargersToAdd[charger] = {
            fwVersion: chargerGroups['Default'].chargers[charger].fwVersion,
            swVersion: chargerGroups['Default'].chargers[charger].swVersion,
            osVersion: chargerGroups['Default'].chargers[charger].osVersion,
            comments: chargerGroups['Default'].chargers[charger].comments || null
          }

        } else if (chargerGroups[editingGroup].chargers[charger]) {
          chargersToAdd[charger] = {
            fwVersion: chargerGroups[editingGroup].chargers[charger].fwVersion,
            swVersion: chargerGroups[editingGroup].chargers[charger].swVersion,
            osVersion: chargerGroups[editingGroup].chargers[charger].osVersion,
            comments: chargerGroups[editingGroup].chargers[charger].comments || null
          }
        }
      }
      await set(ref(getDatabase(fbConf), `voltie/updategroups/${editingGroup}`), {
        chargers: chargersToAdd,
        groupOsVersion: OSVersionSelectorValue != '-' ? OSVersionSelectorValue : '',
        groupSwVersion: SWVersionSelectorValue != '-' ? SWVersionSelectorValue : '',
        groupFwVersion: FWVersionSelectorValue != '-' ? FWVersionSelectorValue : '',
        lastEdit: Date.now()
      })

      // Add all previous chargers to Default
      const toAddToDefault: { [key: string]: { fwVersion: string, swVersion: string, osVersion: string, comments: { author: string, comment: string }[] } } = {}
      for (const charger in chargerGroups[editingGroup].chargers) {
        //toAddToDefault[`voltie/updategroups/Default/chargers/${charger}`] = chargerGroups[editingGroup].chargers[charger]
        toAddToDefault[`voltie/updategroups/Default/chargers/${charger}`] = {
          fwVersion: chargerGroups[editingGroup].chargers[charger].fwVersion,
          swVersion: chargerGroups[editingGroup].chargers[charger].swVersion,
          osVersion: chargerGroups[editingGroup].chargers[charger].osVersion,
          comments: chargerGroups[editingGroup].chargers[charger].comments || null
        }
      }
      await update(ref(getDatabase(fbConf)), toAddToDefault)

      // Delete all now selected chargers from default (not if the group in question is the Default)
      if (editingGroup !== 'Default') {
        const toDeleteFromDefault: { [key: string]: null } = {}
        for (const charger of editChargerGroup_AddedChargers) {
          toDeleteFromDefault[`voltie/updategroups/Default/chargers/${charger}`] = null
        }
        await update(ref(getDatabase(fbConf)), toDeleteFromDefault)
      }

      await fetchChargerGroups()
      setEditingGroup(null)
      setIsAddingGroup(false)
    }
    setEditChargerGroup_isLoading(false)
  }

  async function handleDeleteChargerGroup() {
    if (chargerGroups && isDeletingGroup) {
      const dbRef = ref(getDatabase(fbConf))

      const chargersToAddToDefault: { [key: string]: ChargerType } = {}
      for (const charger in chargerGroups[isDeletingGroup].chargers) {
        chargersToAddToDefault[`voltie/updategroups/Default/chargers/${charger}`] = chargerGroups[isDeletingGroup].chargers[charger]
      }

      await update(dbRef, chargersToAddToDefault)
      await update(dbRef, { [`voltie/updategroups/${isDeletingGroup}`]: null })

      await fetchChargerGroups()
      setIsDeletingGroup(null)
    }
  }

  function handleChargerMenuSelect(value: string, charger: string, chargerGroup: string) {
    if (chargerGroups) {
      switch (value) {
        case t('chargerGroups.forceUpdate'):
          handleForceUpdateCharger(charger)
          break;
        case t('chargerGroups.reset'):
          handleResetCharger(charger)
          break;
        case t('chargerGroups.move'):
          setIsMovingCharger({ charger, chargerGroup })
          break;
      }
    }
  }

  async function handleForceUpdateCharger(chargerId: string) {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 15, "sub_cmd": 1 })
    })
    if (response.ok) {
      setNotification(await response.json())
    } else {
      setNotification(await response.text())
    }
  }

  async function handleResetCharger(chargerId: string) {
    const response = await Comm('/api-firmware/v2/update/reset', {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "chargerId": chargerId })
    })
    if (response.ok) {
      setNotification(await response.text())
    } else {
      setNotification(await response.text())
    }
  }

  async function handleMoveCharger() {
    if (chargerGroups && isMovingCharger) {
      await set(ref(getDatabase(fbConf), `voltie/updategroups/${movingChargerSelectorValue}/chargers/${isMovingCharger.charger}`),
        chargerGroups[isMovingCharger.chargerGroup].chargers[isMovingCharger.charger]
      )
      await update(ref(getDatabase(fbConf)), { [`voltie/updategroups/${isMovingCharger.chargerGroup}/chargers/${isMovingCharger.charger}`]: null })
      setIsMovingCharger(null)
      await fetchChargerGroups()
    }
  }

  function handleRemoveFileFromUploadingFiles(fileName: string) {
    const newFiles = []
    for (const file of addedFilesList) {
      if (file.name != fileName) {
        newFiles.push(file)
      }
    }
    setAddedFilesList(newFiles)
  }

  function handleAddFileToUploadingFiles(fileList: File[]) {
    const approvedFileList = []

    for (const file of fileList) {
      if (file.name.split('.').at(-1) == 'vlt' || file.name.split('.').at(-1) == 'bin' || file.name.split('.').at(-1) == file.name) {
        if (!addedFilesList.find(e => e.name === file.name)) {
          approvedFileList.push(file)
        } else {
          setDragDropFilesError(t('chargerGroups.fileAlreadySelected'))
        }
      } else {
        if (fileList.length > 1) {
          setDragDropFilesError(t('chargerGroups.oneOrMoreFilesWithUnsupportedFileType'))
        } else {
          setDragDropFilesError(t('chargerGroups.unsupportedFileType'))
        }
      }
    }

    if (fileList.length == approvedFileList.length) {
      setDragDropFilesError(null)
    }
    setAddedFilesList([...approvedFileList, ...addedFilesList])
  }

  function handleDropFiles(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()

    if (e.dataTransfer?.files) {
      handleAddFileToUploadingFiles(Array.from(e.dataTransfer?.files))
    }
  }

  async function handleAddFileToUploadingFilesWithFilePicker() {
    // @ts-expect-error The TS filesystem api types a crazy broken... It thinks that showOpenFilePicker does not exists
    const fileHandler = await window.showOpenFilePicker({ multiple: true })
    const files = []
    for (const file of fileHandler) {
      files.push(await file.getFile())
    }
    handleAddFileToUploadingFiles(files)
  }

  // TODO: Not working yet
  async function handleUploadFiles() {
    console.log(`TODO: upload files ${addedFilesList}`)

    /*const API_KEY = 'API_KEY_HERE'
    const bucketName = 'frontend_files'

    for (const file of addedFilesList) {
      await fetch(`https://storage.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=media&name=${file.name}&key=${API_KEY}`, {
        method: 'POST',
        body: file,
        headers: {
          'Content-Type': file.type,
          'Authorization': `Bearer ${API_KEY}`
        }
      })
    }*/

    setUploadedFilesList(addedFilesList)
    setAddedFilesList([])
  }

  function handleGlobalSearch(input: string) {
    if (chargerGroups) {
      if (input != '') {
        const results: { [key: string]: string[] } = {}
        for (const group in chargerGroups) {
          for (const charger in chargerGroups[group].chargers) {
            const searchString = `${charger} ${chargerGroups[group].chargers[charger].userEmail} ${chargerGroups[group].chargers[charger].userId} ${chargerGroups[group].chargers[charger].localName}`
            if (searchString.toLocaleLowerCase().includes(input.toLocaleLowerCase())) {
              if (!results[group]) {
                results[group] = []
              }
              results[group].push(charger)
            }
          }
        }
        if (Object.keys(results).length < 1) {
          setGlobalSearchResults(null)
        } else {
          setGlobalSearchResults(results)
        }
      } else {
        setGlobalSearchResults({})
      }
    }
  }

  function isVersionMismatch(groupFileName: string, chargerFileName: string, version: string, type: string /* SW / FW */) {
    let compareAgainst
    if (chargerFileName && chargerFileName != ' ') {
      compareAgainst = chargerFileName
    } else if (groupFileName && groupFileName !== ' ') {
      compareAgainst = groupFileName
    } else {
      return false
    }

    if (type == 'SW') {
      if (compareAgainst.split('_v')[1].split('_')[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.').replace(/\.0/g, '.').replace(/\.0/g, '.') == version) {
        return false
      } else {
        return true
      }
    } else if (type == 'FW') {
      if (compareAgainst.split('_v')[1].split('.')[0] == version.replace(/\./g, '')) {
        return false
      } else {
        return true
      }
    }
  }

  async function handleEditChargerSoftwareVersion(chargerId: string, group: string, newSoftware: string, updatingSoftware: string) {
    // @ts-expect-error: Should create an enum for updatingSoftware, this is a TODO
    if (chargerGroups && newSoftware != '' && chargerGroups[group].chargers[chargerId][updatingSoftware] != newSoftware) {
      if (newSoftware == 'Delete') {
        newSoftware = ''
      }

      await set(ref(getDatabase(fbConf), `voltie/updategroups/${group}/chargers/${chargerId}/${updatingSoftware}`), newSoftware)
      await set(ref(getDatabase(fbConf), `voltie/updategroups/${group}/lastEdit`), Date.now())
      // @ts-expect-error: Should create an enum for updatingSoftware, this is a TODO
      chargerGroups[group].chargers[chargerId][updatingSoftware] = newSoftware
      chargerGroups[group].lastEdit = Date.now() as unknown as string
      setEditingChargerSoftwareVerion(null)
    }
  }

  return (
    <>
      {notification && (
        <Notification setState={setNotification} label={JSON.stringify(notification)} />
      )}
      <PageTitle label={t('chargerGroups.updateChargers')} />
      <ToolbarButtonContainer>
        <ButtonWithIcon icon="general.upload" label={t('chargerGroups.uploadFile')} onClick={() => setIsUploadingFiles(true)} />
        <WhiteSpace width="16px" />
        <ButtonWithIcon icon="general.refresh" label={t('chargerGroups.refreshCloudFiles')} onClick={fetchBucketFiles} backgroundColor="dark" iconColor="white" />
        <WhiteSpace width="16px" />
        <ButtonWithIcon icon="general.plus" iconColor="white" label={t('chargerGroups.newChargerGroup')} onClick={handleAddNewGroupModalOpen} backgroundColor="dark" />
      </ToolbarButtonContainer>
      <div style={{ display: 'flex' }}>
        <WhiteSpace width="25px" />
        <InputWithIcon onInput={(input) => handleGlobalSearch(input)} width="13vw" placeholder={t('chargerGroups.search')} icon="general.search" iconColor="gray_600" />
        <WhiteSpace width="48px" />
        <FilterDropdownContainer $isActive={filterDropdownValue != 'All'}>
          <Dropdown isDark handleChoose={(value) => setFilterDropdownValue(value)} title={{ label: filterDropdownValue, icon: "general.filter", color: 'white' }} options={[{ label: 'All', color: '#ffffff' }, { label: 'Online', color: '#ffffff' }, { label: 'Offline', color: '#ffffff' }]} />
        </FilterDropdownContainer>
      </div>
      <WhiteSpace height="16px" />
      {!chargerGroups && (
        <div style={{ display: 'flex' }}>
          <WhiteSpace width="25px" />
          <LoaderDots />
        </div>
      )}
      {isDeletingGroup && (
        <Modal>
          <SmallModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsDeletingGroup(null)} />
            </ModalCloseButtonContainer>
            <SmallModalLabel>{t('chargerGroups.areYouSure')}</SmallModalLabel>
            <WhiteSpace height="32px" />
            <SmallModalButtonContainer>
              <Button onclick={handleDeleteChargerGroup} label={t('chargerGroups.delete')} width="calc(50% - 8px)" bgcolor="#FD4E4E" labelcolor="white" />
              <WhiteSpace width="16px" />
              <Button onclick={() => setIsDeletingGroup(null)} label={t('chargerGroups.cancel')} width="calc(50% - 8px)" />
            </SmallModalButtonContainer>
          </SmallModalContainer>
        </Modal>
      )}
      {isMovingCharger && chargerGroups && (
        <Modal>
          <SmallModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsDeletingGroup(null)} />
            </ModalCloseButtonContainer>
            <SmallModalLabel>{t('chargerGroups.whereToMove')}</SmallModalLabel>
            <WhiteSpace height="32px" />
            <Select handleSelect={(value) => setMovingChargerSelectorValue(value)} theme={theme} width="calc(100% - 24px)" icon="general.charger" label={t('chargerGroups.chargerGroup')} options={Object.keys(chargerGroups)} />
            <WhiteSpace height="32px" />
            <SmallModalButtonContainer>
              <Button onclick={handleMoveCharger} label={t('chargerGroups.move')} width="calc(50% - 8px)" bgcolor="#4AB1FC" labelcolor="white" />
              <WhiteSpace width="16px" />
              <Button onclick={() => setIsMovingCharger(null)} label={t('chargerGroups.cancel')} width="calc(50% - 8px)" />
            </SmallModalButtonContainer>
          </SmallModalContainer>
        </Modal>
      )}
      {isUploadingFiles && (
        <Modal>
          <BigModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsUploadingFiles(false)} />
            </ModalCloseButtonContainer>
            <UploadFilesTitle>{t('chargerGroups.fileUpload')}</UploadFilesTitle>
            <WhiteSpace height="24px" />
            <FileDropContainer onDrop={(e) => handleDropFiles(e as unknown as DragEvent)} onDragOver={(e) => e.preventDefault()} onClick={handleAddFileToUploadingFilesWithFilePicker}>
              <WhiteSpace height="16px" />
              <Icon path="chargerGroups.fileDropUpload" width="48px" height="48px" marginLeft="calc((100% - 48px) / 2)" />
              <WhiteSpace height="8px" />
              <FileDropLabel>{t('chargerGroups.dragDropFilesOrBrowse')}</FileDropLabel>
              <WhiteSpace height="16px" />
              {dragDropFilesError && (
                <>
                  <DragDropFilesErrorLabel>{dragDropFilesError}</DragDropFilesErrorLabel>
                  <WhiteSpace height="16px" />
                </>
              )}
            </FileDropContainer>
            <WhiteSpace height="32px" />
            <UploadFilesSubTitle>{t('chargerGroups.selected')}</UploadFilesSubTitle>
            <WhiteSpace height="16px" />
            {addedFilesList && addedFilesList.map(file => (
              <React.Fragment key={file.name}>
                <ListItemWithDeleteButton theme={theme} width="calc(100% - 24px)" icon={(() => {
                  switch (file.name.split('.').at(-1)) {
                    case 'vlt':
                      return "chargerGroups.OS"
                    case 'bin':
                      return "chargerGroups.FW"
                    default:
                      return "chargerGroups.SW"
                  }
                })()} label={(() => {
                  switch (file.name.split('.').at(-1)) {
                    case 'vlt':
                      return t('chargerGroups.operatingSystem')
                    case 'bin':
                      return t('chargerGroups.firmwareSystem')
                    default:
                      return t('chargerGroups.defaultSoftware')
                  }
                })()} value={file.name} handleDelete={() => handleRemoveFileFromUploadingFiles(file.name)} />
                <WhiteSpace height="16px" />
              </React.Fragment>
            ))}
            <UploadFilesSubTitle>{t('chargerGroups.uploaded')}</UploadFilesSubTitle>
            <WhiteSpace height="16px" />
            {uploadedFilesList && uploadedFilesList.map(file => (
              <React.Fragment key={file.name}>
                <ListItem theme={theme} width="calc(100% - 24px)" icon={(() => {
                  switch (file.name.split('.').at(-1)) {
                    case 'vlt':
                      return "chargerGroups.OS"
                    case 'bin':
                      return "chargerGroups.FW"
                    default:
                      return "chargerGroups.SW"
                  }
                })()} label={(() => {
                  switch (file.name.split('.').at(-1)) {
                    case 'vlt':
                      return t('chargerGroups.operatingSystem')
                    case 'bin':
                      return t('chargerGroups.firmwareSystem')
                    default:
                      return t('chargerGroups.defaultSoftware')
                  }
                })()} value={file.name} />
                <WhiteSpace height="16px" />
              </React.Fragment>
            ))}
            <WhiteSpace height="16px" />
            <Button onclick={handleUploadFiles} label={t('chargerGroups.upload')} width='100%' />
          </BigModalContainer>
        </Modal>
      )}
      <GroupsContainer onClick={() => setEditingChargerSoftwareVerion(null)}>
        {chargerGroups && Object.keys(chargerGroups).map((group, groupIndex) => (
          (globalSearchResults && (Object.keys(globalSearchResults).length < 1 || Object.keys(globalSearchResults).includes(group))) &&
          <GroupContainer key={group}>
            <table style={{ width: '98%', cursor: 'pointer' }} onClick={() => handleOpenGroup(group, groupIndex)} ref={el => scrollToGroupRef.current[groupIndex] = el as HTMLElement}>
              <tbody>
                <tr style={{ display: 'flex' }}>
                  <GroupInfoContainer $width="20%">
                    <Icon path={(() => {
                      switch (group) {
                        case 'Installed_chargers':
                          return "chargerGroups.installed_chargersGroup"
                        case 'Betatester':
                          return "chargerGroups.betatesterGroup"
                        case 'Default':
                          return "chargerGroups.defaultGroup"
                        case 'Insider_simuhat':
                          return "chargerGroups.insider_simuhatGroup"
                        default:
                          return "chargerGroups.chargerGroup"
                      }
                    })()} width="30px" height="30px" marginTop="10px" marginRight="16px" />
                    <GroupNameLabel>{group}</GroupNameLabel>
                  </GroupInfoContainer>
                  <GroupInfoContainer $width="10%">
                    <div>
                      <GroupInfoLabel>{t('chargerGroups.charger')}</GroupInfoLabel>
                      <GroupInfoValue>
                        {chargerGroups[group].chargers ? (
                          <>
                            {Object.keys(chargerGroups[group].chargers).length}
                            <span style={{ color: '#57D9BA' }}>
                              {` (${Object.values(chargerGroups[group].chargers).filter(charger => charger.online === true).length})`}
                            </span>
                          </>
                        ) : '0'}
                      </GroupInfoValue>
                    </div>
                  </GroupInfoContainer>
                  <GroupInfoContainer $width="10%">
                    <div>
                      <GroupInfoLabel style={{ marginTop: '10px' }}>{t('chargerGroups.lastEdit')}</GroupInfoLabel>
                      <GroupInfoValue>{chargerGroups[group].lastEdit ? new Date(chargerGroups[group].lastEdit).toString().split(' ').slice(1, 4).join(' ') : '-'}</GroupInfoValue>
                      <GroupInfoLabel>{chargerGroups[group].lastEdit ? new Date(chargerGroups[group].lastEdit).toString().split(' ').slice(4, 6)[0] : ''}</GroupInfoLabel>
                    </div>
                  </GroupInfoContainer>
                  <GroupInfoContainer $width="20%">
                    <Icon path="chargerGroups.SW" width="30px" height="30px" marginTop="10px" marginRight="16px" />
                    <div>
                      <GroupInfoLabel>{t('chargerGroups.defaultSoftware')}</GroupInfoLabel>
                      <GroupInfoValue>{chargerGroups[group].groupSwVersion}</GroupInfoValue>
                    </div>
                  </GroupInfoContainer>
                  <GroupInfoContainer $width="20%">
                    <Icon path="chargerGroups.FW" width="30px" height="30px" marginTop="10px" marginRight="16px" />
                    <div>
                      <GroupInfoLabel>{t('chargerGroups.firmwareSystem')}</GroupInfoLabel>
                      <GroupInfoValue>{chargerGroups[group].groupFwVersion}</GroupInfoValue>
                    </div>
                  </GroupInfoContainer>
                  <GroupInfoContainer $width="20%">
                    <Icon path="chargerGroups.OS" width="30px" height="30px" marginTop="10px" marginRight="16px" />
                    <div>
                      <GroupInfoLabel>{t('chargerGroups.operatingSystem')}</GroupInfoLabel>
                      <GroupInfoValue>{chargerGroups[group].groupOsVersion}</GroupInfoValue>
                    </div>
                  </GroupInfoContainer>
                </tr>
              </tbody>
            </table>
            {group !== 'Installed_chargers' && group !== 'Betatester' && group !== 'Default' && (
              <DeleteButtonContainer onClick={(e) => { e.stopPropagation(); setIsDeletingGroup(group) }}>
                <Icon path="general.trash" width="100%" height="100%" color="danger" />
              </DeleteButtonContainer>
            )}
            <EditButtonContainer onClick={(e) => { e.stopPropagation(); handleEditGroupModalOpen(group) }}>
              <Icon path="general.wrench" width="100%" height="100%" />
            </EditButtonContainer>
            <OpenButtonContainer $isOpen={group == openGroupName} onClick={(e) => { e.stopPropagation(); handleOpenGroup(group, groupIndex) }}>
              <Icon path="general.upArrow" width="100%" height="100%" />
            </OpenButtonContainer>
            {(group == openGroupName || Object.keys(globalSearchResults).length > 0) && (
              <>
                <WhiteSpace height="10px" />
                <GroupDividerLine />
                <WhiteSpace height="24px" />
                <GroupChargersTable>
                  <tbody>
                    <ChargerInfoRow>
                      <td style={{ width: '30px' }}>{/* To make place for the charger icon */}</td>
                      <td style={{ width: '16%' }}>
                        <GroupChargersTableLabel>{t('chargerGroups.chargerIdUserEmailLocalName')}</GroupChargersTableLabel>
                      </td>
                      <td style={{ width: '20%' }}>
                        <GroupChargersTableLabel>{t('chargerGroups.state')}</GroupChargersTableLabel>
                      </td>
                      <td style={{ width: '20%' }}>
                        <GroupChargersTableLabel>{t('chargerGroups.defaultSoftware')}</GroupChargersTableLabel>
                      </td>
                      <td>
                        <GroupChargersTableLabel>{t('chargerGroups.firmwareSystem')}</GroupChargersTableLabel>
                      </td>
                      <td style={{ width: '19%' }}>
                        <GroupChargersTableLabel>{t('chargerGroups.operatingSystem')}</GroupChargersTableLabel>
                      </td>
                      <td style={{ width: '20px' }}>{/* To make place for the charger menu */}</td>
                    </ChargerInfoRow>
                    {chargerGroups[group].chargers && Object.keys(chargerGroups[group].chargers).map(charger => (
                      (Object.keys(globalSearchResults).length < 1 || globalSearchResults[group].includes(charger)) &&
                      (filterDropdownValue == 'All' ? true : filterDropdownValue == 'Online' ? chargerGroups[group].chargers[charger].online : !chargerGroups[group].chargers[charger].online) &&
                      <ChargerTableRow key={charger}>
                        <td>
                          <Icon path={chargerGroups[group].chargers[charger].online ? "chargerGroups.chargerOnline" : "chargerGroups.chargerOffline" } width="30px" height="30px" marginLeft="9px" marginRight="16px" />
                        </td>
                        <td style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                          <div>
                            <Navigator to={`/chargers/${charger}`}><ChargerIdLabel>{charger}</ChargerIdLabel></Navigator>
                            <ChargerEmailLabel>{chargerGroups[group].chargers[charger].userEmail}</ChargerEmailLabel>
                            <ChargerLocalNameLabel>{chargerGroups[group].chargers[charger].localName != ' ' ? chargerGroups[group].chargers[charger].localName : '-'}</ChargerLocalNameLabel>
                          </div>
                        </td>
                        <td>
                          {chargerGroups[group].chargers[charger].online ? (
                            <ChargerStatus $status='online'>Online</ChargerStatus>
                          ) : (
                            <div>
                              <ChargerStatus $status='offline'>Offline</ChargerStatus>
                              <TableTimeAgoLabel>{dateToTimeAgoString(new Date(chargerGroups[group].chargers[charger].lastPresence.replace(/\./g, '-')))}</TableTimeAgoLabel>
                            </div>
                          )}
                        </td>
                        <td style={{ position: 'relative' }}>
                          <div>
                            <ChargerSoftwareVerisonLabel $isMismatch={isVersionMismatch(chargerGroups[group].groupSwVersion, chargerGroups[group].chargers[charger].swVersion, chargerGroups[group].chargers[charger].swVersionNumber, 'SW')}>{chargerGroups[group].chargers[charger].swVersionNumber || '-'}</ChargerSoftwareVerisonLabel>
                            <WhiteSpace height="22px" />
                            {(editingChargerSoftwareVerion && editingChargerSoftwareVerion.chargerId == charger && editingChargerSoftwareVerion.software == 'SW') ? (
                              <>
                                <WhiteSpace height="4px" />
                                <Select handleSelect={(value) => handleEditChargerSoftwareVersion(charger, group, value, 'swVersion')} theme={theme} width="12vw" isAbsolute options={[chargerGroups[group].chargers[charger].swVersion, ...bucketFiles.swVersion, 'Delete']} />
                              </>
                            ) : (
                              <div style={{ display: 'flex', position: 'relative' }}>
                                {chargerGroups[group].chargers[charger].swVersion ? (
                                  <>
                                    <GroupInfoValue style={{ margin: '0px', marginTop: '4px' }}>{chargerGroups[group].chargers[charger].swVersion}</GroupInfoValue>
                                    <WhiteSpace width="8px" />
                                    <ChargerSoftwareIconContainer onClick={(e) => { e.stopPropagation(); setEditingChargerSoftwareVerion({ chargerId: charger, software: 'SW' }) }}>
                                      <Icon path="general.edit" color="gray_900" width="100%" height="100%" />
                                    </ChargerSoftwareIconContainer>
                                    <WhiteSpace width="8px" />
                                    <ChargerSoftwareIconContainer onClick={() => handleEditChargerSoftwareVersion(charger, group, 'Delete', 'swVersion')}>
                                      <Icon path="general.trash" color="danger" width="125%" height="125%" marginTop="2px" />
                                    </ChargerSoftwareIconContainer>
                                  </>
                                ) : (
                                  <ChargerSoftwareIconContainer onClick={(e) => { e.stopPropagation(); setEditingChargerSoftwareVerion({ chargerId: charger, software: 'SW' }) }}>
                                    <Icon path="general.edit" color="gray_900" width="100%" height="100%" />
                                  </ChargerSoftwareIconContainer>
                                )}
                              </div>
                            )}
                          </div>
                        </td>
                        <td style={{ position: 'relative' }}>
                          <div>
                            <ChargerSoftwareVerisonLabel $isMismatch={isVersionMismatch(chargerGroups[group].groupFwVersion, chargerGroups[group].chargers[charger].fwVersion, chargerGroups[group].chargers[charger].fwVersionNumber, 'FW')}>{chargerGroups[group].chargers[charger].fwVersionNumber || '-'}</ChargerSoftwareVerisonLabel>
                            <WhiteSpace height="22px" />
                            {(editingChargerSoftwareVerion && editingChargerSoftwareVerion.chargerId == charger && editingChargerSoftwareVerion.software == 'FW') ? (
                              <>
                                <WhiteSpace height="4px" />
                                <Select handleSelect={(value) => handleEditChargerSoftwareVersion(charger, group, value, 'fwVersion')} theme={theme} width="12vw" isAbsolute options={[chargerGroups[group].chargers[charger].fwVersion, ...bucketFiles.fwVersion, 'Delete']} />
                              </>
                            ) : (
                              <div style={{ display: 'flex', position: 'relative' }}>
                                {chargerGroups[group].chargers[charger].fwVersion ? (
                                  <>
                                    <GroupInfoValue style={{ margin: '0px', marginTop: '4px' }}>{chargerGroups[group].chargers[charger].fwVersion}</GroupInfoValue>
                                    <WhiteSpace width="8px" />
                                    <ChargerSoftwareIconContainer onClick={(e) => { e.stopPropagation(); setEditingChargerSoftwareVerion({ chargerId: charger, software: 'FW' }) }}>
                                      <Icon path="general.edit" color="gray_900" width="100%" height="100%" />
                                    </ChargerSoftwareIconContainer>
                                    <WhiteSpace width="8px" />
                                    <ChargerSoftwareIconContainer onClick={() => handleEditChargerSoftwareVersion(charger, group, 'Delete', 'fwVersion')}>
                                      <Icon path="general.trash" color="danger" width="125%" height="125%" marginTop="2px" />
                                    </ChargerSoftwareIconContainer>
                                  </>
                                ) : (
                                  <ChargerSoftwareIconContainer onClick={(e) => { e.stopPropagation(); setEditingChargerSoftwareVerion({ chargerId: charger, software: 'FW' }) }}>
                                    <Icon path="general.edit" color="gray_900" width="100%" height="100%" />
                                  </ChargerSoftwareIconContainer>
                                )}
                              </div>
                            )}
                          </div>
                        </td>
                        <td style={{ position: 'relative' }}>
                          <div>
                            <ChargerSoftwareVerisonLabel $isMismatch={isVersionMismatch(chargerGroups[group].groupOsVersion, chargerGroups[group].chargers[charger].osVersion, chargerGroups[group].chargers[charger].osVersionNumber, 'OS')}>{chargerGroups[group].chargers[charger].osVersionNumber || '-'}</ChargerSoftwareVerisonLabel>
                            <WhiteSpace height="22px" />
                            {(editingChargerSoftwareVerion && editingChargerSoftwareVerion.chargerId == charger && editingChargerSoftwareVerion.software == 'OS') ? (
                              <>
                                <WhiteSpace height="4px" />
                                <Select handleSelect={(value) => handleEditChargerSoftwareVersion(charger, group, value, 'osVersion')} theme={theme} width="12vw" isAbsolute options={[chargerGroups[group].chargers[charger].osVersion, ...bucketFiles.osVersion, 'Delete']} />
                              </>
                            ) : (
                              <div style={{ display: 'flex', position: 'relative' }}>
                                {chargerGroups[group].chargers[charger].osVersion ? (
                                  <>
                                    <GroupInfoValue style={{ margin: '0px', marginTop: '4px' }}>{chargerGroups[group].chargers[charger].osVersion}</GroupInfoValue>
                                    <WhiteSpace width="8px" />
                                    <ChargerSoftwareIconContainer onClick={(e) => { e.stopPropagation(); setEditingChargerSoftwareVerion({ chargerId: charger, software: 'OS' }) }}>
                                      <Icon path="general.edit" color="gray_900" width="100%" height="100%" />
                                    </ChargerSoftwareIconContainer>
                                    <WhiteSpace width="8px" />
                                    <ChargerSoftwareIconContainer onClick={() => handleEditChargerSoftwareVersion(charger, group, 'Delete', 'osVersion')}>
                                      <Icon path="general.trash" color="danger" width="125%" height="125%" marginTop="2px" />
                                    </ChargerSoftwareIconContainer>
                                  </>
                                ) : (
                                  <ChargerSoftwareIconContainer onClick={(e) => { e.stopPropagation(); setEditingChargerSoftwareVerion({ chargerId: charger, software: 'OS' }) }}>
                                    <Icon path="general.edit" color="gray_900" width="100%" height="100%" />
                                  </ChargerSoftwareIconContainer>
                                )}
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <Dropdown handleChoose={(value) => handleChargerMenuSelect(value, charger, group)} title={{ icon: "general.more", label: '' }} options={[{ icon: "chargerGroups.datasheet", label: t('chargerGroups.datasheet'), link: `/chargers/${charger}` }, { icon: "general.log", label: t('chargerGroups.logs'), link: `/logs/charger/${charger}` }, { icon: 'general.refresh', label: t('chargerGroups.forceUpdate') }, { icon: "chargerGroups.move", label: t('chargerGroups.move') }, { icon: "general.error", label: t('chargerGroups.reset'), color: '#FD4E4E' }]} showOptionContainerOnLeft />
                        </td>
                      </ChargerTableRow>
                    ))}
                  </tbody>
                </GroupChargersTable>
              </>
            )}
          </GroupContainer>
        ))}
      </GroupsContainer>
      {(editingGroup || isAddingGroup) && chargerGroups && (
        <Modal align="right">
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => { setEditingGroup(null); setIsAddingGroup(false) }} />
            </ModalCloseButtonContainer>
            {isAddingGroup ? (
              <ModalTitle>{t('chargerGroups.newChargerGroup')}</ModalTitle>
            ) : (
              <ModalTitle>{editingGroup}</ModalTitle>
            )}
            <WhiteSpace height="34px" />
            {isAddingGroup && (
              <>
                <Input onInput={(input) => setEditChargerGroup_NameInput(input)} width="18vw" placeholder={t('chargerGroups.newChargerGroupName')} />
                <WhiteSpace height="24px" />
              </>
            )}
            <ModalCategoryTitle>{t('chargerGroups.uniformSetup')}</ModalCategoryTitle>
            <WhiteSpace height="16px" />
            <Select handleSelect={(value) => setOSVersionSelectorValue(value)} theme={theme} width="18vw" icon="chargerGroups.OS" label={t('chargerGroups.operatingSystem')} options={editingGroup ? [chargerGroups[editingGroup].groupOsVersion, ...bucketFiles.osVersion, '-'] : ['-', ...bucketFiles.osVersion]} />
            <WhiteSpace height="16px" />
            <Select handleSelect={(value) => setSWVersionSelectorValue(value)} theme={theme} width="18vw" icon="chargerGroups.SW" label={t('chargerGroups.defaultSoftware')} options={editingGroup ? [chargerGroups[editingGroup].groupSwVersion, ...bucketFiles.swVersion, '-'] : ['-', ...bucketFiles.swVersion]} />
            <WhiteSpace height="16px" />
            <Select handleSelect={(value) => setFWVersionSelectorValue(value)} theme={theme} width="18vw" icon="chargerGroups.FW" label={t('chargerGroups.firmwareSystem')} options={editingGroup ? [chargerGroups[editingGroup].groupFwVersion, ...bucketFiles.fwVersion, '-'] : ['-', ...bucketFiles.fwVersion]} />
            <WhiteSpace height="24px" />
            <ModalCategoryDivider />
            <WhiteSpace height="24px" />
            <ModalCategoryTitle>{t('chargerGroups.addChargers')}</ModalCategoryTitle>
            <WhiteSpace height="16px" />
            <InputWithIcon onInput={(input) => handleEditChargerGroup_Search(input)} width="18vw" placeholder={t('chargerGroups.search')} icon="general.search" iconColor="gray_600" />
            {editChargerGroup_AddedChargers && editChargerGroup_AddedChargers.map(charger => (
              editChargerGroup_SearchResults.includes(charger) && (
                <ModalChargerContainer key={charger} onClick={() => handleEditChargerGroup_RemoveCharger(charger)}>
                  <Icon path="general.checkboxFull" width="16px" height="16px" marginTop="12px" marginLeft="12px" />
                  <div>
                    <ModalChargerLabel>{t('chargerGroups.chargerId')}</ModalChargerLabel>
                    <ModalChargerValue>{charger}</ModalChargerValue>
                  </div>
                </ModalChargerContainer>
              )
            ))}
            {editChargerGroup_SearchResults && editChargerGroup_SearchResults.map(charger => (
              !editChargerGroup_AddedChargers.includes(charger) && (
                <ModalChargerContainer key={charger} onClick={() => handleEditChargerGroup_AddCharger(charger)}>
                  <Icon path="general.checkboxEmpty" width="16px" height="16px" marginTop="12px" marginLeft="12px" />
                  <div>
                    <ModalChargerLabel>{t('chargerGroups.chargerId')}</ModalChargerLabel>
                    <ModalChargerValue>{charger}</ModalChargerValue>
                  </div>
                </ModalChargerContainer>
              )
            ))}
            <ModalSaveButtonContainer>
              <ModalSaveButton onClick={isAddingGroup ? handleAddNewChargerGroup : handleSaveEditedChargerGroup}>{editChargerGroup_isLoading ? <LoaderDots /> : isAddingGroup ? t('chargerGroups.add') : t('chargerGroups.save')}</ModalSaveButton>
              <ModalCancelButton onClick={() => { setEditingGroup(null); setIsAddingGroup(false) }}>{t('chargerGroups.cancel')}</ModalCancelButton>
            </ModalSaveButtonContainer>
          </ModalContainer>
        </Modal>
      )}
    </>
  )
}