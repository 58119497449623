import { useTranslation } from "react-i18next"
import WhiteSpace from "../../components/Whitespace"
import PageTitle from "../../components/PageTitle"
import styled from "styled-components"
import { useEffect, useState } from "react"
import InputWithIcon from "../../components/InputWithIcon"
import PaginationNavigator from "../../modules/PaginationNavigator"
import Comm from "../../Comm"
import ChargerType from "../../types/ChargerType"
import Dropdown from "../../components/Dropdown"
import ButtonWithIcon from "../../components/ButtonWithIcon"
import Icon from "../../Icon"

interface UserType {
  access_level: number,
  email: string,
  expiry_date: number,
  name: string,
  user_id: string
}

interface RfidType {
  email: string,
  enabled: boolean,
  id: string,
  name: string,
  last_use: number
}

const BlockContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 24px;
`

const ItemsContainer = styled.div`
  width: 100%;
  height: calc((90vh - 310px) / 2);
  background-color: ${props => props.theme.colors.white};
  border-radius: 16px;
  overflow-y: scroll;
`

const ItemTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const ItemInfoRow = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.gray_300};
`

const ItemTableLabel = styled.p`
  font-weight: 600;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_500};
`

const ItemTableRow = styled.tr`
  border-bottom: 1px dashed ${props => props.theme.colors.gray_300};
  position: relative;
`

const ToolbarContaier = styled.div`
  width: 100%;
  display: flex;
`

const RefreshTimerLabel = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 0px;
  text-align: center;
`

const TableLabel = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin-top: 16px;
`

const TableLabelDividedTop = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin-top: 16px;
`

const TableLabelDividedBottom = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin-top: 16px;
`

const RfidStatusLabel = styled.p<{ $status: string }>` // active / inactive
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.colors.white};
  border-radius: 40px;
  width: 70px;
  line-height: 22px;
  text-align: center;
  background-color: ${props => {
    switch (props.$status) {
      case 'active':
        return props.theme.colors.success
      case 'inactive':
        return props.theme.colors.gray_400
    }
  }};
`

const UserPermissionLabel = styled.p<{ $status: number }>` // 0 - 8 => none / installer / user / admin / backend / api_call / expired / owner
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.colors.white};
  border-radius: 40px;
  width: 70px;
  line-height: 22px;
  text-align: center;
  background-color: ${props => {
    switch (props.$status) {
      case 0: return props.theme.colors.gray_400
      case 1: return props.theme.colors.gray_400
      case 2: return props.theme.colors.primary
      case 3: return props.theme.colors.gray_900
      case 4: return props.theme.colors.gray_400
      case 5: return props.theme.colors.gray_400
      case 6: return props.theme.colors.gray_400
      case 7: return props.theme.colors.success
    }
  }};
`

export default function UsersRfid({ charger }: { charger: ChargerType }) {
  const { t } = useTranslation()

  const [users, setUsers] = useState<UserType[]>()
  const [rfids, setRfids] = useState<RfidType[]>()
  const [lastUsersRefresh, setLastUsersRefresh] = useState<string>('')
  const [lastRfidsRefresh, setLastRfidsRefresh] = useState<string>('')
  const [usersSearchResults, setUsersSearchResults] = useState<UserType[] | null>(null)
  const [rfidsSearchResults, setRfidsSearchResults] = useState<RfidType[] | null>(null)
  const [usersPage, setUsersPage] = useState<number>(1)
  const [usersPageStep, setUsersPageStep] = useState<number>(0)
  const [rfidsPage, setRfidsPage] = useState<number>(1)
  const [rfidsPageStep, setRfidsPageStep] = useState<number>(0)

  useEffect(() => {
    async function fetchUsersAndRfids() {
      await fetchUsers()
      await fetchRfids()
    }
    fetchUsersAndRfids()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function fetchUsers() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 46 })
    })
    const result = await response.json()
    setUsers(result.users)
    setLastUsersRefresh(new Date().toString().split(' ')[4])
  }

  async function fetchRfids() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 23 })
    })
    const result = await response.json()
    setRfids(result.rfid_list)
    setLastRfidsRefresh(new Date().toString().split(' ')[4])
  }

  function handleRefreshUsers() {
    setUsers([])
    fetchUsers()
  }

  function handleRefreshRfids() {
    setRfids([])
    fetchRfids()
  }

  function handleUsersSearch(input: string) {
    if (users) {
      if (input != '') {
        const results: UserType[] = []
        for (const user of users) {
          const searchString = `${user.email} ${user.name} ${user.user_id}`
          if (searchString.toLocaleLowerCase().includes(input.toLocaleLowerCase())) {
            results.push(user)
          }
        }
        if (results.length < 1) {
          setUsersSearchResults([])
        } else {
          setUsersSearchResults(results)
        }
      } else {
        setUsersSearchResults(null)
      }
    }
  }

  function handleRfidsSearch(input: string) {
    if (rfids) {
      if (input != '') {
        const results: RfidType[] = []
        for (const rfid of rfids) {
          const searchString = `${rfid.email} ${rfid.id} ${rfid.name}`
          if (searchString.toLocaleLowerCase().includes(input.toLocaleLowerCase())) {
            results.push(rfid)
          }
        }
        if (results.length < 1) {
          setRfidsSearchResults([])
        } else {
          setRfidsSearchResults(results)
        }
      } else {
        setRfidsSearchResults(null)
      }
    }
  }

  return (
    <>
      <WhiteSpace height={'24px'} />
      <BlockContainer>
        <WhiteSpace height={'18px'} />
        <ToolbarContaier>
          <WhiteSpace width={'12px'} />
          <div style={{ marginTop: '-10px' }}>
            <PageTitle label={t('charger.users')} />
          </div>
          <WhiteSpace width={'24px'} />
          <div>
            <ButtonWithIcon icon="general.refresh" label={t('logs.refresh')} onClick={handleRefreshUsers} backgroundColor="success" iconColor="white" />
            <WhiteSpace height={'6px'} />
            <RefreshTimerLabel>{lastUsersRefresh}</RefreshTimerLabel>
          </div>
          <WhiteSpace width={'42px'} />
          <InputWithIcon onInput={(input) => handleUsersSearch(input)} width="12vw" placeholder={t('logs.search')} icon="general.search" iconColor="gray_600" />
        </ToolbarContaier>
        <ItemsContainer>
          <ItemTable>
            <tbody>
              <ItemInfoRow>
                <td style={{ width: '30px' }}>{/* To make place for the charger icon */}</td>
                <td style={{ width: '20%' }}>
                  <ItemTableLabel>{t('charger.userEmail')}</ItemTableLabel>
                </td>
                <td style={{ width: '20%' }}>
                  <ItemTableLabel>{t('charger.nameUserUid')}</ItemTableLabel>
                </td>
                <td style={{ width: '20%' }}>
                  <ItemTableLabel>{t('charger.permissionLevel')}</ItemTableLabel>
                </td>
                <td style={{ width: '20%' }}>
                  <ItemTableLabel>{t('charger.created')}</ItemTableLabel>
                </td>
                <td>
                  <ItemTableLabel>{t('charger.signedIn')}</ItemTableLabel>
                </td>
                <td style={{ width: '20px' }}>{/* To make place for the charger menu */}</td>
              </ItemInfoRow>
              {users && (usersSearchResults ? usersSearchResults : users).map((user, index) => (
                (usersSearchResults ? true : (index + 1 > (usersPage - 1) * usersPageStep && index < usersPage * usersPageStep)) &&
                <ItemTableRow key={user.user_id}>
                  <td>
                    <Icon path="chargers.user" width="30px" height="30px" marginTop="5px" marginLeft="9px" marginRight="16px" />
                  </td>
                  <td style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <TableLabel>{user.email || '-'}</TableLabel>
                  </td>
                  <td>
                    <TableLabelDividedTop>{user.name || '-'}</TableLabelDividedTop>
                    <TableLabelDividedBottom>{user.user_id || '-'}</TableLabelDividedBottom>
                  </td>
                  <td>
                    <UserPermissionLabel $status={user.access_level}>{t(`charger.userAccessLevel.${user.access_level}`)}</UserPermissionLabel>
                  </td>
                  <td>
                    <TableLabel>{'-'}</TableLabel>
                  </td>
                  <td>
                    <TableLabel>{'-'}</TableLabel>
                  </td>
                  <td>
                    <Dropdown handleChoose={(value) => console.log(value, user)} title={{ icon: "general.more", label: '' }} options={[{ icon: 'general.edit', label: t('charger.edit') }, { icon: "general.trash", label: t('charger.delete'), color: '#FD4E4E' }]} showOptionContainerOnLeft />
                  </td>
                </ItemTableRow>
              ))}
            </tbody>
          </ItemTable>
        </ItemsContainer>
      </BlockContainer>
      <WhiteSpace height={'18px'} />
      {!usersSearchResults ? (
        <PaginationNavigator notAbsolute width="100%" numberOfEntries={users ? users.length : 0} page={usersPage} setPage={setUsersPage} step={usersPageStep} setStep={setUsersPageStep} />
      ) : (
        <div style={{ height: '40px' }}></div>
      )}







      <WhiteSpace height={'12px'} />
      <BlockContainer>
        <WhiteSpace height={'18px'} />
        <ToolbarContaier>
          <WhiteSpace width={'12px'} />
          <div style={{ marginTop: '-10px' }}>
            <PageTitle label={t('charger.rfidManagement')} />
          </div>
          <WhiteSpace width={'24px'} />
          <div>
            <ButtonWithIcon icon="general.refresh" label={t('logs.refresh')} onClick={handleRefreshRfids} backgroundColor="success" iconColor="white" />
            <WhiteSpace height={'6px'} />
            <RefreshTimerLabel>{lastRfidsRefresh}</RefreshTimerLabel>
          </div>
          <WhiteSpace width={'42px'} />
          <InputWithIcon onInput={(input) => handleRfidsSearch(input)} width="12vw" placeholder={t('logs.search')} icon="general.search" iconColor="gray_600" />
        </ToolbarContaier>
        <ItemsContainer>
          <ItemTable>
            <tbody>
              <ItemInfoRow>
                <td style={{ width: '30px' }}>{/* To make place for the charger icon */}</td>
                <td style={{ width: '20%' }}>
                  <ItemTableLabel>{t('charger.rfidName')}</ItemTableLabel>
                </td>
                <td style={{ width: '20%' }}>
                  <ItemTableLabel>{t('charger.rfidState')}</ItemTableLabel>
                </td>
                <td style={{ width: '20%' }}>
                  <ItemTableLabel>{t('charger.rfidNumber')}</ItemTableLabel>
                </td>
                <td style={{ width: '20%' }}>
                  <ItemTableLabel>{t('charger.lastUse')}</ItemTableLabel>
                </td>
                <td>
                  <ItemTableLabel>{t('charger.user')}</ItemTableLabel>
                </td>
                <td style={{ width: '20px' }}>{/* To make place for the charger menu */}</td>
              </ItemInfoRow>
              {rfids && (rfidsSearchResults ? rfidsSearchResults : rfids).map((rfid, index) => (
                (rfidsSearchResults ? true : (index + 1 > (rfidsPage - 1) * rfidsPageStep && index < rfidsPage * rfidsPageStep)) &&
                <ItemTableRow key={rfid.id}>
                  <td>
                    <Icon path="chargers.rfidTag" width="30px" height="30px" marginTop="5px" marginLeft="9px" marginRight="16px" />
                  </td>
                  <td style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <TableLabel>{rfid.name || '-'}</TableLabel>
                  </td>
                  <td>
                    <RfidStatusLabel $status={rfid.enabled ? 'active' : 'inactive'}>{rfid.enabled ? t('charger.active') : t('charger.inactive')}</RfidStatusLabel>
                  </td>
                  <td>
                    <TableLabel>{rfid.id || '-'}</TableLabel>
                  </td>
                  <td>
                    <div>
                      <TableLabelDividedTop>{rfid.last_use ? new Date(rfid.last_use * 1000).toISOString().split('T')[0].replace(/-/g, '.') : '-'}</TableLabelDividedTop>
                      <TableLabelDividedBottom>{rfid.last_use ? new Date(rfid.last_use * 1000).toISOString().split('T')[1].split('.')[0] : ''}</TableLabelDividedBottom>
                    </div>
                  </td>
                  <td>
                    <TableLabel>{rfid.email || '-'}</TableLabel>
                  </td>
                  <td>
                    <Dropdown handleChoose={(value) => console.log(value, rfid)} title={{ icon: "general.more", label: '' }} options={[{ icon: 'general.edit', label: t('charger.edit') }, { icon: "general.trash", label: t('charger.delete'), color: '#FD4E4E' }]} showOptionContainerOnLeft />
                  </td>
                </ItemTableRow>
              ))}
            </tbody>
          </ItemTable>
        </ItemsContainer>
      </BlockContainer>
      <WhiteSpace height={'18px'} />
      {!rfidsSearchResults ? (
        <PaginationNavigator notAbsolute width="100%" numberOfEntries={rfids ? rfids.length : 0} page={rfidsPage} setPage={setRfidsPage} step={rfidsPageStep} setStep={setRfidsPageStep} />
      ) : (
        <div style={{ height: '40px' }}></div>
      )}
    </>
  )
}